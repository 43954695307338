.diary {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}

.diary label {
    margin-bottom: 5px;
}

.diary .comments {
    padding-right: 20px;
}

.comments{
    display: flex;
    flex-direction: column;
    max-width: 620px;
    margin-right: 30px;
}

.containerDate {
    width: 200px;
}

.separacion {
    margin-top: 20px;
}

.double{
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 15px;
}

.diary .btnSubmit {
    align-self: center;
    background-color: #f89820;
    width: 100px;
    height: 30px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 5px 0 #f89820 inset, 0 0 5px 4px #f89820;
    }
}