.input {
    /* height: 30px; */
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
}

.input input[type="text"] {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
