.modalBackground {
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200);
    position: fixed;
    top: 0;
    left: 0;
}

.modalContainer {
    width: 550px;
    height: 520px;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px 25px;
}

p .footer{
    max-width: 160px;
}

.modalContainer .title {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.modalContainer .subtitle{
    text-align: left;
    margin-left: 20px;
}

.modalContainer .title h1{
    font-size: 20px;
    margin: 0;
}

.modalContainer .subtitle h1{
    font-size: 18px;
    margin: 0;
}


.modalContainer .body {
    display: flex;
    padding: 0;
    justify-content: left;
    font-size: 14px;
    gap: 10px;
}
.modalContainer .body label{
    width: 200px;
    display: flex;
    text-align: start;
    margin-right: 15px;
}

.modalContainer .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.submitBtn{
    background-color: #f89820;
    width: 100px;
    height: 30px;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.downloadBtn{
    background-color: rgb(13,110,184);
    margin: 10px;
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
}

.footer{
    display: contents;
}

.mensajeContainer{
    width: 145px;
}
.cancelBtn{
    background-color: rgb(250, 36, 79);
    width: 100px;
    height: 30px;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    justify-items: center;
    align-items: center;
    position: absolute;
}

.body ul{
    list-style: none;
    display: flex;
    flex-direction: column;
}

.body li{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding: 3px;
}

select{
    border-radius: 20px;
    padding: 2px 5px;
}

p .modalContainer{
    max-width: 150px;
}