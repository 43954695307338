.contenedorBtnExportar{
    display: flex;
    justify-content: end;
    padding-right: 10px;
}


.btnExportar{
    width: 150px;
    font-size:8pt;
    font-weight: bold;
    padding: 5px;
    color: #000000;
    letter-spacing: 2px;
    background: #E7E6E6;
    border: 2px solid #000000;
    border-radius: 20px;
    transition: all 0.7s ease 0s;
    cursor: pointer;
}
.btnExportar:hover{
    color:#000000;
    background: #F8971D;
    box-shadow: -5px -5px 10px 0px #444e77, 5px 5px 10px 0px #444e77;
    transform: scale(1.1);
}