body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('https://img.freepik.com/vector-gratis/concepto-fondo-abstracto-semitono_23-2148587700.jpg?w=740&t=st=1685980712~exp=1685981312~hmac=8db4a48d919cbfdfeafce4a3c747a6fb0dc551aeea572cdca02406fbf4dff4eb');   */
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('./resources/images/4931043.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  max-width: 850px;
  margin: 0 auto;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

input[type="submit"] {
  border-color: #f89820;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 30px;
  border: none;
  background-color: #f89820;
  box-shadow: 0 0 40px 40px #f89820 inset, 0 0 0 0 #f89820;
  color: #fff;
  padding: 10px 20px;
  border-radius: 3px;
  margin: 15px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  padding-bottom: 30px;

  &:hover {
    box-shadow: 0 0 5px 0 #f89820 inset, 0 0 5px 4px #f89820;
  }
}

.btn {
  border-color: #f89820;
  font-weight: bold;
  font-size: 18px;
  border: none;
  background-color: #f89820;
  box-shadow: 0 0 40px 40px #f89820 inset, 0 0 0 0 #f89820;
  color: #fff;
  padding: 10px 20px;
  border-radius: 3px;
  margin: 15px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.btn:hover {
  box-shadow: 0 0 5px 0 #f89820 inset, 0 0 5px 4px #f89820;
}

input {
  border: solid 1px #ccc;
  border-radius: 20px;
  height: 20px;
  padding: 5px 10px;
}

input[type="text"].input {
  border: solid 1px #ccc;
  border-radius: 20px;
  padding: 4px 8px;
}

input[type="date"] {
  border: solid 1px #ccc;
  border-radius: 20px;
  padding: 5px 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

input[type="datetime-local"] {
  border: solid 1px #ccc;
  border-radius: 20px;
  padding: 5px 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    &:focus {
    border: 2px solid #f99a23;
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }
}

label {
  padding-top: 5px;
}

a {
  color: rgb(13, 110, 184);
  font-weight: bold;
  text-decoration: none;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

select {
  border: solid 1px #ccc;
  border-radius: 20px;
  height: 30px;
  padding: 5px 10px;
}

input[type="text"],
input[type="date"],
select {
  &:focus {
    border: 2px solid #f99a23;
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }
}

input[type="checkbox"] {
  display: inline-flex;
  margin: 0;
  width: 20px;
  height: 20px;
  justify-items: center;
  align-items: center;
}