.entryContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
    margin-bottom: 20px;
}
.entryContainer2{
    display: flexbox;
    gap: 10px;
    padding: 10px 0;
    margin-bottom: 20px;
}

.loading{
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="checkbox"] {
    display: inline-flex;
    margin: 0;
    width: 20px;
    height: 20px;
    justify-items: center;
    align-items: center;
}

.activo{
    margin: 20px;
}

.displayGrid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

label .fechas{ 
    margin-top: 5px;
}