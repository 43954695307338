.nav{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav a{
    color: #222;
    text-decoration: none;
    padding: 25px 25px;
    border-bottom: solid 2px transparent;
}

.nav a:hover {
    border-bottom: solid 2px #222;
}

.logo{
    width: 100px;
    margin: 15px 20px;
}

.btn{
    margin-inline-start: 0.67em;
    /* margin: 0; */
    padding: 0 20px;
    transition: 0.3s all ease-out;
    cursor: pointer;
    border: none;
    background: local;
    text-align: center;
    
}

.btn:hover {
    color: #ffa600;
}

.div{
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
}