.infoPopup table {
    border-collapse: collapse;
    width: 300px;
    table-layout: fixed;
    align-self: center;
    justify-items: center;
}

.infoPopup th{
    background-color: #eee;
    font-weight: bold;
    text-align: left;
    padding: 10px;
}

.infoPopup td {
    border: 1px solid #ccc;
    padding: 0 10px;
}

.infoPopup td,
th {
    text-align: center;
    vertical-align: middle;
} 

.infoPopup {
    position: absolute;
    top: 425px;
    left: -330px;
    background-color: white;
    border: 1px solid gray;
    padding: 10px;
    z-index: 1;
    text-align: center;
    width: 350px;
}

.title{
    margin: 0;
    padding: 0;
}

.btnInfo {
    margin-left: 15px;
    background-color: rgb(255, 255, 255);
    position: sticky;
    border: none;
}

.btnInfo button {
    width: 40px;
}

.colorVerde {
    background-color: greenyellow;
}

.colorAmarillo {
    background-color: yellow;
}

.colorNaranja {
    background-color: orange;
}

.colorRojo {
    background-color: rgb(255, 33, 17);
}