.boxContent2 {
    border: 2px solid #cccc;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 30px;
}

.watermark {
    position: fixed;
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -999;
    right: 0;
    bottom: 0;
    opacity: 0.15;
    align-items: center;
    justify-content: center;
    width: 1000px;
}

.client {
    margin-left: 43px;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.client label {
    margin-right: 40px;
}

.client input {
    margin-left: 15px;
}

.boxColored2 {
    border: 1px solid #cccc;
    text-align: center;
    background-color: #ececec;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
}

.modalContainer2.riskTable p {
    max-width: 160px;
}

.modalContainer2 .title {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.modalContainer2.subtitle {
    text-align: left;
    margin-left: 20px;
}

.modalContainer2.title h1 {
    font-size: 20px;
    margin: 0;
}

.modalTitleContainer {
    margin: 0;
}

.modalContainer2 .subtitle h1 {
    font-size: 18px;
    margin: 0;
}


.modalContainer2.body2 {
    display: flex;
    padding: 0;
    justify-content: left;
    font-size: 14px;
    gap: 10px;
}

.body2 label {
    width: 200px;
    display: flex;
    text-align: start;
    margin-right: 15px;
}


.infoClient label {
    text-align: start;
}

.infoClient {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    padding: 3px;
    width: 100%;
}

.infoClient input{
    width: 90%;
}

.infoFuncionaries{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding: 3px;
    width: 99%;
}

.modalContainer2 .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.submitBtn2 {
    background-color: #f89820;
    width: 100px;
    height: 30px;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.downloadBtn2 {
    background-color: rgb(13, 110, 184);
    margin: 10px;
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
}

.modalContainer2 .footer2 {
    display: flex;
    flex-direction: row;
    align-items: center
}

.PDFButton {
    margin-left: 20px;
}

.cancelBtn2 {
    background-color: rgb(250, 36, 79);
    width: 100px;
    height: 30px;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.body2 ul {
    list-style: none;
    display: flex;
    flex-direction: column;
}

.body2 li {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding: 3px;
}

.body2 textarea {
    width: 760px;
    max-width: 1000px;
    /* Ancho fijo */
    height: 200px;
    /* Alto fijo */
}

.boxContent2 select {
    border-radius: 20px;
    padding: 2px 5px;
}

.modalContainer2 p {
    max-width: 150px;
}

.modalContainer2 .riskTable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border: none;
}

.modalContainer2 .riskTable th {
    background-color: #eee;
    font-weight: bold;
    padding: 10px;
    border: none;
}

.modalContainer2.riskTable td {
    border: 1px solid #ccc;
    padding: 0 10px;
    border-radius: 10px;
    border: none;
}

.modalContainer2.riskTable td,
th {
    vertical-align: middle;
}

.title2 {
    margin: 0;
    padding: 0;
}

.btnInfo2 {
    margin-left: 15px;
    background-color: rgb(255, 255, 255);
    position: sticky;
    border: none;
}

.btnInfo2 button {
    width: 40px;
}

.colorVerde2 {
    background-color: greenyellow;
}

.colorAmarillo2 {
    background-color: yellow;
}

.colorNaranja2 {
    background-color: orange;
}

.colorRojo2 {
    background-color: rgb(255, 33, 17);
}

.textArea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    resize: none;
    border-radius: 20px;
    padding: 10px 10px;
    border: 1px solid #cccc;

    &:focus {
        border: 2px solid #f99a23;
        outline: none;
        box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
    }
}