/*
Colores corporativos de pilgrim 
#F28A00  - Naranja
#2B2D2C - Azul petroleo
#8C8E8C - Gris  */

:root{
    --green: hsl(162,45%,56%);
    --lead: hsl(162,2%,56%);
    --blue: hsl(220,85%,57%);
    --text-white: hsl(0,0%,100%);
    --text-light-gray: hsl(0,0%,90%);
    --body-bg-color: hsl(200,54%,12%);
    --glass-bg-color: hsl(0,0%,100%,0.05);
    --border-color: hsla(0,0%,100%,0.25);
    --blur: blur(10px);
    --button-hover-color: hsla(0,0%,0%,0.3);
}

.appContainer{
    background-image: url(/src/resources/images/fondo-pilgrim.png);
    color: var(--text-white);
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
}

.appContainer::after,
.appContainer::before{
    content: '';
    position: fixed;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    z-index: -1;
    filter: blur(150px);
    opacity: 0.5;
}

.appContainer::before{
    background-color: var(--green);
    left: 0;
    bottom: 0;
    transform: translate(-50%);
}

.appContainer::after{
    background-color: var(--violet);
    right: 0;
    top: 0;
    transform: translate(-50%);
}

.formMain{
    min-height: 100vh;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formContent{
    width: 100%;
    max-width: 450px;
    position: relative;
    z-index: 1;
}

.formContent .box{
    border-color: var(--glass-bg-color);
    border: 1px solid var(--border-color);
    padding: 0px 0px;
    border-radius: 20px;
    backdrop-filter: var(--blur);
    opacity: 0;
    animation: zoomIn 1s ease forwards;
    animation-delay: 1s, 2s;
}

.section{
    padding: 20px 30px;
}

.division1{
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 350px;
    padding: 20px 20px;
    background-color: #ffffff;
    border: 10px solid var(--border-color);
    margin-bottom: 20px;
} 

.division1 img{
    object-fit: contain;
    width: 90%;
} 

/* .division1 img.contain{
    object-fit: contain;
}
 */
.imageContain{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 20px solid var(--text-white);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.formContent .h3{
    font-size: 28px;
    text-align: center;
    color: var(--text-white);
    font-weight: 500;
}

.formContent form{
    margin: 0px 0px;
}

.formContent p{
    text-align: center;
}

.formContent .inputBox{
    margin-top: 20px;
    width: 100%;
}

.formContent ::placeholder{
    color: inherit;
    opacity: 1;
}

.formContent .inputControl{
    display: block;
    height: 45px;
    width: 95%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--border-color);
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    font-size: 16px;
}

.formContent .inputControl:focus{
    outline: none;
    width: 100%;
}

.formContent .input-link{
    margin-top: 10px;
    text-align: right;
}

.formContent .btn{
    margin-top: 25px;
    margin-bottom: 10px;
    height: 45px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: inherit;
    cursor: pointer;
    border-radius: 25px;
    user-select: none;
    background-image: linear-gradient(185deg, #ffd10f 0, #ffc012 12.5%, #fcad18 25%, #f49a1e 37.5%, #e78522 50%, #d97125 62.5%, #cb6028 75%, #bf512b 87.5%, #b4442e 100%);
    border: none;
    color: (var(--text-white));
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.formContent .btn::before{
    content:'';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: background-color 0.5s ease;
    z-index: -1;
}

.formContent .btn:hover::before{
    background-color: var(--button-hover-color);
}

@media(max-widht: 575px){
    .appContainer::after,
    .appContainer::before{
        height: 250px;
        width: 250px;
        filter: blur(100px);
    }
    .formContent .box{
        padding: 30px;
    }
    .formContent h3{
        font-size: 24px;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}