.secondaryContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 10px 0;
}

.container {
  margin: 10px 0;
}

.containerTable {
  display: flex;
  width: 100%;
  max-height: 90%;
  margin: .8rem auto;
  overflow: auto;
}

.displayGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.displayGrid select {
  width: 170px;
}

.searchSolicitude td {
  border-style: none;
}

.tableInfo tbody {
  width: 100vh;
}

.containerTable table,
th,
td {
  padding: 0.1rem;
  overflow: hidden;
  border-collapse: collapse;
  text-align: left;
  width: fit-content;
  font-size: 13px;
}

.tableStyle thead,
th {
  align-items: left;
}

.picture {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerTable table {
  width: 100%;
  table-layout: auto;
}

.containerTable thead {
  border-start-start-radius: 20px;
  border-start-end-radius: 20px;
}

.containerTable .tableTitles th {
  padding-top: 5px;
  padding-bottom: 5px;
}

.tableStyle thead {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #d5d1defe;
}

.containerTable tbody,
tr:nth-child(even) {
  background-color: #0000000b;
}

.containerTable tbody,
tr:hover {
  background-color: #fff6;
}

.rowAlarm {
  /* animation: flashBackground 2s infinite; /* Cambia la duración según desees */ 
  background-color: rgba(160, 218, 235, 0.5) !important; /* Añade !important para asegurar la prioridad */
}

@media (max-width: 1000px) {
  .containerTable td:not(:first-of-type) {
    min-width: 12.1rem;
  }
}

@keyframes flashBackground {
  0%, 100% {
    background-color: rgba(0, 17, 255, 0.603);
  }
  50% {
    background-color: inherit; /* Mantiene el color original */
  }
}

