.clock {
    display: block;
    position: absolute;
    top: 15vh;
    right: 30vh;
    transform: translateX(100%);
    padding: 0px 20px;
    width: 140px;
}

.clock h1{
    font-size: 20px;
}