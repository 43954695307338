.boxContent {
    border: 2px solid #cccc;
    border-radius: 10px;
    margin-top: 10px;
}

.boxColored {
    border: 1px solid #cccc;
    padding: 0px 20px;
    background-color: #ececec;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.boxContent .fechaCreacion {
    margin: 10px 10px;
    text-align: right;
    width: auto;
    border: 1px solid #eee;
    /* padding: 10px 10px; */
}

.diary {
    display: flex;
    margin-top: 30px;
    padding: 20px 20px;
}

.mapContainer {
    align-items: center;
    justify-content: center;
}

.containerDairy {
    margin-top: 0;
    margin-left: 15px;
    margin-bottom: 20px;
}


.mainContainerTable table {
    width: 100%;
    align-self: center;
    justify-self: center;
}

.tableInfo td {
    border-style: none;
    width: 60%;
    text-align: left;
}

.tableInfo th {
    width: 30%;
    text-align: left ;
    position: relative;
}

.tableInfo tbody {
    width: 100vh;
}

.tableInfo p {
    margin: 0;
}

.diaryHistorial {
    margin-bottom: 20px;
}

.tableStyle{
    width: 100%;
    padding: 15px;
}

.margin{
    margin-left: 15px;
}

.viewBtn{
    background-color: #3c6bec;
    width: 200px;
    height: 30px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 30px;
}

.downloadBtn{
    background-color: #43db3e;
    width: 200px;
    height: 30px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 30px;
}

.finishBtn{
    background-color: #f89820;
    width: 200px;
    height: 30px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 30px;
}

.cancelBtn{
    background-color: rgb(250, 36, 79);
    width: 200px;
    height: 30px;
    margin: 5px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.middle{
    align-self: center;
}

.deleteContainer{
    display: flex;
    justify-content: center;
}

.btnContainer {
    margin: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btnGoBack{
    margin-top: 10px;
}