.containerToggle{
    padding: 1px;
    margin-bottom:15px ;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    border-radius: 20px;
}
.mainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.checkBtn{
    opacity: 0;
    height: 0;
    width: 0;
}

.toggleButton{
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(202, 201, 201);
    border-radius: 20px;
    transition: 0.4s ease;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}
.toggleButton:before{
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;

    background-color: rgb(255, 33, 17);
    transition: 0.4s ease;
    border-radius: 50px;  
    box-shadow: rgba(50, 46, 96, -0.91) 8px -10px 6px -6px inset, rgba(0, 0, 0, 0.3) 6px 10px 13px -3px inset;
}

.checkBtn:checked + .toggleButton:before{
    transform: translateX(33px);
    background-color: rgb(133, 221, 0);
    box-shadow: rgba(50, 46, 96, -0.91) 8px -10px 6px -6px inset, rgba(0, 0, 0, 0.3) 6px 10px 13px -3px inset;
}

.evaluationModule {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    padding-top: 0;
}

.label {
    margin-right: 10px;
}