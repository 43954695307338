.container {
    width: 98%;
    max-width: 1200px;
    margin: auto;
    padding: 40px 0;
    gap: 20px;
    display: flex;
    flex-direction: row;
}

.submitBtn{
    background-color: #f89820;
    width: 100px;
    height: 30px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.cancelBtn{
    background-color: rgb(250, 36, 79);
    width: 100px;
    height: 30px;
    margin: 5px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.secondContainer{
    width: fit-content;
    height: fit-content;
    display: inline;
}

.secondContainer input{
    margin: 15px 0;
    border-radius: 2px;
    padding: 8px 16px;
}

.secondContainer label{
    margin-top: 20px;
    padding-top: 10px;
}

.secondContainer Autocomplete{
    margin-top: 20px;
    padding-top: 10px;
}

.thirdContainer{
    border-style: dashed;
    margin: 20px 0;
    border-radius: 5px;
    padding: 10px;
    border-color: darkgray;
}

.forthContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.forthContainer button{
    padding: 5px 5px;
    margin: 2px 2px;
    background-color: rgb(197, 236, 107);
    border-radius: 10px;
    border-style: none;
}
