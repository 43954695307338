.formulario {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* padding: 10px 0; */
    margin-bottom: 60px;
}

.imgCheck{
    width: 100px;
    height: 100px;
    opacity: 0.7;
    align-self: center;
}

.checkContainer{
    display: flex;
    width: 900px;
    height: 300px;
    border-radius: 10px;
    border: 2px #cccccc dashed; 
    align-items: center;
    justify-content: center;
}

.inputWell {
    border: 2px solid greenyellow !important;
}

.inputWrong {
    border: 2px solid red !important;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="checkbox"] {
    display: inline-flex;
    margin: 0;
    width: 20px;
    height: 20px;
    justify-items: center;
    align-items: center;
}

.activo {
    margin: 20px;
}

.displayGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @media (max-widht: 800px) {
        grid-template-columns: 1fr;
    }
}

.displayGridUbicaciones {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    /* border: solid 2px #ccc; */
    border-radius: 5px;
    padding: 5px 0;
}

label .displayGridUbicaciones {
    display: flex;
    justify-content: center;
    align-content: center;
}

.files {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 0;
}

.borderContainer {
    border: 1px solid #CCC;
    height: fit-content;
    border-radius: 10px;
}

.contenedor .funcionariosLabel {
    margin-right: 10px;
}

.contenedor{
    padding-bottom: 30px;
}

.botonUpload {
    margin-top: 25px;
    width: fit-content;
    height: fit-content;
}

.circleCritico {
    background-color: rgb(255, 33, 17);
}

.circleAlto {
    background-color: orange;
}

.circleMedio {
    background-color: yellow;
}

.circleBajo {
    background-color: rgb(133, 221, 0);
}

.circleBajo,
.circleMedio,
.circleAlto,
.circleCritico {
    color: antiquewhite;
    padding: 8px 8px;
    text-align: center;
    border-style: none;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.formulario .evaluationModule {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    padding-top: 0;
}

.label {
    margin-right: 10px;
}

.displayGrid .fechas {
    display: flex;
    flex-direction: row;
}

.fechas .estilos {
    display: flex;
    justify-content: space-between;
}

.formulario .submitBtn {
    border-color: #f89820;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 30px;
    border: none;
    background-color: #f89820;
    box-shadow: 0 0 40px 40px #f89820 inset, 0 0 0 0 #f89820;
    color: #fff;
    padding: 10px 20px;
    border-radius: 3px;
    margin: 15px;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    padding-bottom: 30px;
    &:hover {
        box-shadow: 0 0 5px 0 #f89820 inset, 0 0 5px 4px #f89820;
    }
}

