.servicesSubmenu {
    width: 10rem;
    position: absolute;
    list-style: none;
    text-align: start;
    align-self: flex-start;
    background-color: white;
    top: 50px;
    border: 2px solid #cccc;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 83px -15px #cccc;
    z-index: 9999;
}

.servicesSubmenu li {
    cursor: pointer;
}

.subMenuItem {
    text-decoration: none;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.subMenuItem a {
    color: #222;
    padding: 10px;
    width: 100%;
    height: 100%;
}


